import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParamsModel } from '../models/params-model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private DeviceOverviewPageQueryParams$: BehaviorSubject<ParamsModel> =
    new BehaviorSubject<ParamsModel>(null);

  constructor() {}

  public setDeviceOverviewPageQueryParams(params: ParamsModel) {
    this.DeviceOverviewPageQueryParams$.next(params);
  }

  public getDeviceOverviewPageQueryParams(): Observable<ParamsModel> {
    return this.DeviceOverviewPageQueryParams$.asObservable();
  }
}
