import { ParamsModel } from '@src/app/models/params-model';
import * as _ from 'lodash';

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

// Source: https://stackoverflow.com/a/53943624
export function repeatTimes(times: number) {
  return {
    [Symbol.iterator]: function* () {
      for (let i = 0; i < times; i++, yield) {}
    }
  };
}

export const isBoolean = (val) => {
  return typeof val === 'boolean';
};

export const capitalizeBool = (value: boolean): string => {
  return value !== null
    ? value.toString().charAt(0).toUpperCase() + value.toString().slice(1)
    : '';
};

export const MILLI_TO_VOLTS = 0.001;

export const convertMillivoltsToVolts = (millivolts: number): number => {
  const volts = millivolts * MILLI_TO_VOLTS;
  return Math.round(volts * 10) / 10;
};

export const celsiusToFahrenheit = (celsius: number): number => {
  return parseFloat(((celsius * 9) / 5 + 32).toFixed(1));
};

export const convertMiBtoGB = (mib: number): string => {
  const gb = (mib * 1024 * 1024) / (1000 * 1000 * 1000);
  return gb.toFixed(1);
};

// This function is used on distinctUntilChanged when calling issue and event view aggergation api
export function checkRouteParamsAreChanged(
  previous: ParamsModel,
  current: ParamsModel
): boolean {
  if (previous && current) {
    return _.isEqual(
      _.omit(current, ['page', 'per_page', 'sort_by', 'sort_order']), //ignoring page, per_page, sort param changes
      _.omit(previous, ['page', 'per_page', 'sort_by', 'sort_order'])
    );
  }
  return false;
}
